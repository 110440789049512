import React, { useContext, useEffect, useState } from 'react'
import { ActivityIndicator } from 'react-native-web'

import firebase, { asFirebase, logActivity } from '../../../../../utils/firebase'
import * as brand from '@shared/brand'

import {
    ACTIVITY_TYPE_CLEANING_STATUS,
    CLEANING_STATUS_CLEAN,
    CLEANING_STATUS_DIRTY,
    OCCUPANCY_CHECKIN,
    OCCUPANCY_CHECKOUT,
    OCCUPANCY_TURNOVER
} from '@shared/txt-constants'
import { calculateCleaningStatus, getActiveRule } from '@shared/cleaning-calculator'
import moment from 'moment-timezone'
import { AuthContext } from '../../../../Auth/AuthContext'
import {
    ActivityStruct,
    AreaCleaningStatus,
    AreaStruct,
    BookingStruct,
    getMiniUserObject,
    OrgStruct,
    RuleStruct
} from '@shared/dataObjects'
import { DateBubble } from './DateBubble'
import { Occupancy } from '@shared/firestore-structs'
import { PlanningInfo } from './PlanningInfo'
import { isDateInMonth, sectionMonths } from '@shared/date-helpers'
import { RedButton } from '../../../../../components/buttons/RedButton'
import AlertPortal from 'app/components/alerts/AlertPortal'
import { updateCleaningSchedule } from 'app/modules/HousekeepingDashboard/api'
import { useFeatureToggle } from '../../../../../features'
import { getActivitiesRange } from '@shared/activity-data'

export type CleaningDateProps = AreaStruct & {
    date: number
    cleaningStatus: AreaCleaningStatus
    occupancy: Occupancy
    selectedDateNumber: number
    onClick: (date: number, cleaningStatus?: AreaCleaningStatus) => void
    empty?: boolean
}

type CleaningScheduleSection = {
    month: string
    dates: CleaningDateProps[]
}

export function CleaningScheduleLocal({
    allBookings,
    guestData,
    selectedDateNumber
}: {
    allBookings: BookingStruct[]
    selectedDateNumber: number
    guestData: { area: AreaStruct; booking: BookingStruct; rules: RuleStruct[]; organization: OrgStruct }
}) {
    const [loading, setloading] = useState(false)
    const [sections, setSections] = useState<CleaningScheduleSection[] | null>(null)
    const [optOutDates, setOptOutDates] = useState(guestData.booking.optOutDates || [])
    const [optInDates, setOptInDates] = useState(guestData.booking.optInDates || [])
    const [activities, setActivities] = useState<ActivityStruct[]>([])
    const [showMassActionConfirmation, setShowMassActionConfirmation] = useState(false)
    const { isFeatureOn } = useFeatureToggle()

    const authContext = useContext(AuthContext)
    if (!authContext.organization || !authContext.user) return null
    const { organization, user } = authContext

    const setOptAction = organization.allowOptIn ? setOptInDates : setOptOutDates
    const optField = organization.allowOptIn ? 'optInDates' : 'optOutDates'

    const { booking, area, rules } = guestData
    let debouncedUpdate: any // Don't really care about correctly typing this as the correct type is bogus anyway
    let updateQueue: BookingStruct[] = []

    const bookingDates = [...booking.bookingDates]

    const updateSchedule = async (before: string[], after: string[]) => {
        await Promise.all(updateQueue.map(update => updateCleaningSchedule(update.key, area.key, before, after, organization, user)))
        updateQueue = []
    }

    const addToQueue = (before: string[], after: string[], booking: BookingStruct) => {
        updateQueue.push(booking)

        // Debounce the handleUpdates function
        clearTimeout(debouncedUpdate)
        debouncedUpdate = window.setTimeout(() => {
            updateSchedule(before, after)
        }, 2000) // 2 seconds delay
    }

    // User x added date x to cleaning plan
    // User x removed date x from cleaning plan
    const onToggleClick = () => {
        const groupBookings = allBookings.filter(x => x._external?.parentReservationID === booking._external?.parentReservationID)
        if (booking._external && booking._external.parentReservationID && groupBookings.length > 1) {
            setShowMassActionConfirmation(true)
            return
        }
        onConfirmToggleAll()
    }

    const toggleAll = (bookings: BookingStruct[]) => {
        const stayoverDays = [...bookingDates].length - 2
        const selectedDates = organization.allowOptIn ? optInDates : optOutDates
        if (selectedDates.length < stayoverDays) {
            bookings.map(x => {
                const bd = [...bookings[0].bookingDates].filter(d => parseInt(d) >= selectedDateNumber)
                bd?.pop()
                addToQueue(x['optOutDates'] || [], bd, x)
            })
            setOptAction(booking.bookingDates.filter(d => parseInt(d) >= selectedDateNumber))
        } else {
            bookings.map(x => {
                const bd = x[optField]?.filter(d => parseInt(d) >= selectedDateNumber)
                bd?.pop()
                addToQueue(bd || [], [], x)
            })
            setOptAction([])
        }
    }

    const onConfirmToggleAll = () => {
        const groupBookings = allBookings.filter(
            x =>
                (booking._external &&
                    booking._external.parentReservationID &&
                    booking._external.parentReservationID === x._external?.parentReservationID) ||
                x.key === booking.key
        )
        toggleAll(groupBookings)
        setShowMassActionConfirmation(false)
    }

    const onCancelToggleAll = () => {
        toggleAll([booking])
        setShowMassActionConfirmation(false)
    }

    const onBubbleClick = (date: number, cleaningStatus: AreaCleaningStatus) => {
        const dates = organization.allowOptIn ? [...optInDates] : [...optOutDates]
        const index = dates.indexOf(date.toString())
        const activitiesOnDate = activities.filter(x => x.date === date && x.type === ACTIVITY_TYPE_CLEANING_STATUS)
        const hasCleaningActiviyOnDate = activitiesOnDate.length > 0
        if (isFeatureOn('stayery-cleaning-schedule')) {
            let cleaningStatusToSet
            if (hasCleaningActiviyOnDate) {
                const mostRecentActivity = activitiesOnDate.sort((a, b) => b.created - a.created)[0]
                if (mostRecentActivity.change.after === CLEANING_STATUS_CLEAN) {
                    cleaningStatusToSet = CLEANING_STATUS_DIRTY
                } else {
                    cleaningStatusToSet = CLEANING_STATUS_CLEAN
                }
            } else {
                cleaningStatusToSet = cleaningStatus === CLEANING_STATUS_CLEAN ? CLEANING_STATUS_DIRTY : CLEANING_STATUS_CLEAN
            }
            const changeObj = {
                field: 'cleaningStatus',
                before: cleaningStatus,
                after: cleaningStatusToSet
            }
            logActivity(asFirebase(firebase), user, area.key, ACTIVITY_TYPE_CLEANING_STATUS, date, changeObj)
            setActivities([
                ...activities,
                {
                    date,
                    type: ACTIVITY_TYPE_CLEANING_STATUS,
                    change: changeObj,
                    created: moment().valueOf(),
                    areaKey: area.key,
                    key: moment().valueOf().toString(),
                    organizationKey: organization.key,
                    user: getMiniUserObject(user)
                }
            ])

            return
        }

        if (index > -1) {
            dates.splice(index, 1)
        } else {
            dates.push(date.toString())
        }
        addToQueue(optInDates, dates, booking)
        setOptAction(dates)
    }

    useEffect(() => {
        const getActivities = async () => {
            const activitiesSnap = await getActivitiesRange(
                asFirebase(firebase),
                organization.key,
                booking.checkinDate,
                booking.checkoutDate,
                area.key
            ).get()
            setActivities(activitiesSnap.docs.map(x => x.data() as ActivityStruct))
        }

        if (isFeatureOn('stayery-cleaning-schedule')) {
            getActivities()
        }
    }, [selectedDateNumber])

    useEffect(() => {
        const months = sectionMonths(booking.bookingDates.map(x => parseInt(x)))
        const sections: CleaningScheduleSection[] = []

        if (Object.keys(months).length === 0) return

        const schedules: CleaningDateProps[] = []
        const bookingDates = [...booking.bookingDates].sort((a, b) => parseInt(a) - parseInt(b))

        const calculations = bookingDates.map(async (d: string) => {
            booking.optOutDates = [...optOutDates]
            booking.optInDates = [...optInDates]

            const resultObject = await calculateCleaningStatus(
                asFirebase(firebase),
                [Object.assign({}, area)],
                parseInt(d),
                organization,
                [booking],
                isFeatureOn('stayery-cleaning-schedule') ? activities.filter(x => x.date === parseInt(d)) : [],
                rules,
                true
            )
            return resultObject[0]
        })

        Promise.all(calculations).then(values => {
            values.map((a, i) => {
                let occupancy = a.occupancy
                const date = parseInt(bookingDates[i])
                const firstOrLast = [OCCUPANCY_TURNOVER, OCCUPANCY_CHECKIN, OCCUPANCY_CHECKOUT].includes(occupancy)

                if (!firstOrLast) {
                    occupancy = getActiveRule(area, [booking], parseInt(booking.bookingDates[i]), organization).occupancy!
                }

                schedules.push({
                    date,
                    cleaningStatus: a.cleaningStatus,
                    occupancy: occupancy,
                    onClick: (date, cleaningStatus) => {
                        onBubbleClick(date, a.cleaningStatus)
                    }
                } as CleaningDateProps)
            })

            for (const month of Object.keys(months)) {
                const monthSchedules = schedules.filter((x: CleaningDateProps) => {
                    return isDateInMonth(x.date, month)
                })

                if (monthSchedules.length > 0) {
                    const firstDate = moment(monthSchedules[0].date)
                    const dayOfWeek = firstDate.day()
                    const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // 0 (Sunday) -> 6 (previous Monday), others -> dayOfWeek - 1

                    const emptyDays = bookingDates.length > 6 ? Array(daysToMonday).fill({ empty: true }) : []

                    sections.push({
                        month: month,
                        dates: [...emptyDays, ...monthSchedules]
                    })
                }
            }

            setSections(sections)
        })
    }, [optOutDates.length, setOptOutDates, optInDates.length, setOptInDates, selectedDateNumber, activities])

    const renderSections = () => {
        return sections!.map((section, i) => {
            return (
                <div key={i} className="mt-5">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
                        <div className=" font-weight-bolder font-size-h2 ">{moment(section.month).format('MMMM YYYY')}</div>
                        <div>
                            <RedButton
                                disabled={moment(selectedDateNumber).isBefore(moment().startOf('day'))}
                                onClick={() => !moment(selectedDateNumber).isBefore(moment().startOf('day')) && onToggleClick()}>
                                Select all
                            </RedButton>
                        </div>
                    </div>
                    <div className="row seven-cols">
                        {section.dates.map((date, i) => {
                            const subheader =
                                date.occupancy === OCCUPANCY_CHECKIN || date.occupancy === OCCUPANCY_TURNOVER
                                    ? 'Check in'
                                    : date.occupancy === OCCUPANCY_CHECKOUT
                                    ? 'Check out'
                                    : ''

                            if (date.empty) {
                                return <div key={i} className="col-4 col-xs-6 col-md-1 text-center"></div>
                            }

                            return (
                                <div key={i} className="col-4 col-xs-6 col-md-1 text-center">
                                    <div className="font-size-sm font-weight-bolder mb-2" style={{ minHeight: 18 }}>
                                        {subheader}
                                    </div>
                                    <span className="flex-center d-flex">
                                        <DateBubble {...date} selectedDateNumber={selectedDateNumber} />
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        })
    }

    return (
        <>
            {showMassActionConfirmation && (
                <AlertPortal
                    title={'Group reservation action'}
                    text={
                        'This booking is a part of a larger group reservation, do you want to apply this action to all reservations in the group?'
                    }
                    confirmBtnText={'Yes'}
                    cancelBtnText={'No, only this booking'}
                    onConfirm={onConfirmToggleAll}
                    onCancel={onCancelToggleAll}
                />
            )}
            <form className="">
                <div
                    style={{
                        position: 'absolute',
                        right: '50%',
                        top: '40%',
                        height: 50,
                        width: 50,
                        zIndex: 10000
                    }}>
                    {loading && <ActivityIndicator size="large" color={brand.getBrand().navBarColor} style={{}} />}
                </div>

                <div className="form">
                    {/* begin::Body */}

                    <div className="d-flex justify-content-between flex-wrap">
                        <PlanningInfo />
                    </div>

                    <div className="form-group">{sections && sections.length > 0 && renderSections()}</div>

                    {/* end::Body */}
                </div>
            </form>
        </>
    )
}
